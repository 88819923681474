import { UseQueryResult, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API } from "@/utils/apiConstants";

interface UseTokenResponse {
  token: string | undefined;
  ESredirectUrl: string | undefined;
}

export const useToken = (
  tempToken: string
): UseQueryResult<UseTokenResponse> => {
  return useQuery<UseTokenResponse>({
    enabled: !!tempToken,
    queryKey: ["token", tempToken],
    queryFn: async (): Promise<UseTokenResponse> => {
      const { data } = await axios.post(
        `${API.baseAuthEndpoint}/apps/access_tokens`,
        { token: tempToken }
      );
      return {
        token: data?.access_token?.token,
        ESredirectUrl: data?.access_token?.redirect_back_url,
      };
    },
    staleTime: Infinity,
  });
};
