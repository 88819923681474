import { z } from "zod";

export const formSchema = z.object({
  nickname: z.string().min(1, ''),
  accountNumber: z.string().length(9, ''),
  apiKey: z.string().length(14, ''),
  apiSecret: z.string().length(16, ''),
});

export const validate = (data: unknown) => {
  return formSchema.parse(data);
};

export type FormValues = z.infer<typeof formSchema>;
