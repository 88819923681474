import { FormValues, formSchema } from './schema';
import { CourierFormComponentProps } from '../types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/Form';
import { ControlledInput } from '@/components/ControlledInput';
import { Alert } from 'easyship-components';
import { Separator } from '@/components/Separator';

export const COMPATIBLE_UMBRELLA_NAMES = {
  ups: 'UPS (UPS Ready® Certified)',
} satisfies Record<string, string>;

export const FormComponent = ({
  onSuccess,
  umbrellaName,
}: CourierFormComponentProps<FormValues>) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  return (
    <>
      <p>
        By connecting your {umbrellaName} account, you will no longer be eligible to use the
        discounted UPS rates offered by Easyship.
      </p>
      <Alert className="mt-4">
        After entering your {umbrellaName} account information below, click the 'CONNECT' button.
        You'll then be redirected to the UPS website to verify your account.
      </Alert>
      <Separator />
      <Form
        onSubmit={handleSubmit(onSuccess)}
        hasValidationErrors={Object.keys(errors).length !== 0}
      >
        <ControlledInput
          name="nickname"
          control={control}
          label="Account Name"
          placeholder="e.g. MyCarrierAccount 01"
          statusText="The account name will be used to identify your account"
        />

        <ControlledInput
          name="accountNumber"
          control={control}
          label="Account Number"
          placeholder="e.g. 123456789"
          statusText="6-character UPS Shipper Number"
        />
        <p className="mb-1 text-ink-500 text-sm">
          UPS, the UPS brandmark, UPS Ready®️, and the color brown are trademarks of United Parcel
          Service of America, Inc. All Rights Reserved.
        </p>
      </Form>
    </>
  );
};
