import { courierUmbrellaName } from '@/helpers/dataFormattingUtils';
import { Check } from 'easyship-components/icons';
import { useLocation } from 'react-router-dom';

export const SuccessPage = (): React.ReactElement => {
  const location = useLocation();

  const state = location.state;

  if (state?.redirectUrl) {
    setTimeout(() => {
      window.open(state.redirectUrl, '_self');
    }, 5000);
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen text-ink-900">
      <div className="text-[72px]">
        <Check className="w-20 h-20 mb-4 text-green-500" />
      </div>
      <h4 className="mb-3 text-3xl font-bold">Success</h4>
      {state?.message ? (
        <p>{state?.message}</p>
      ) : (
        `${courierUmbrellaName[state?.courier] || 'Your courier'} was successfully added.`
      )}
    </div>
  );
};
