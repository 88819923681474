import { Layout } from '@/components/Layout';
import { routesList } from '@/router/routes-list';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as GenericForm from '@/forms/generic/GenericForm';
import * as ApgForm from '@/forms/apg/ApgForm';
import * as AuPostForm from '@/forms/auPost/AuPostForm';
import * as CanadaPostFrom from '@/forms/canadaPost/CanadaPostForm';
import * as DhlForm from '@/forms/dhl/DhlForm';
import * as FedexForm from '@/forms/fedex/FedexForm';
import * as PassportForm from '@/forms/passport/PassportForm';
import * as RoyalMailForm from '@/forms/royalMail/RoyalMailForm';
import * as SendleForm from '@/forms/sendle/SendleForm';
import * as SkypostalForm from '@/forms/skypostal/SkyPostalForm';
import * as UpsForm from '@/forms/ups/UpsForm';
import {
  SubmitCourierData,
  SubmitCourierResponse,
  useSubmitCourierMutation,
} from '@/hooks/useSubmitCourierMutation';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { postMessage } from '@/helpers/postMessage';
import axios from 'axios';
import { useSubmitCanadaPostMutation } from '@/hooks/useSubmitCanadaPostMutation';
import { isSingleCountryCourier } from '@/helpers/courierFilters';
import { useUserContext } from '@/providers/UserProvider';
import { useUpsRedirectUrlMutation } from '@/hooks/useUpsRedirectUrlMutation';
import { Pulse } from 'easyship-components';

const formComponentList = [
  ApgForm,
  AuPostForm,
  CanadaPostFrom,
  DhlForm,
  FedexForm,
  PassportForm,
  RoyalMailForm,
  SendleForm,
  SkypostalForm,
  UpsForm,
];

export const AddCourierPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { country, selectedCourier } = useUserContext();
  const { mutate: submitCourier, isPending: courierMutationPending } = useSubmitCourierMutation();
  const { mutate: submitCanadaPost, isPending: canadaPostMutationPending } =
    useSubmitCanadaPostMutation();
  const { data: currentUser } = useCurrentUser();
  const { mutate: fetchUpsRedirectUrl, isPending: upsMutationPending } =
    useUpsRedirectUrlMutation();

  if (!selectedCourier) throw new Error('Missing selected courier');

  const isLoading = courierMutationPending || canadaPostMutationPending || upsMutationPending;

  // If courier connection is successful, redirect to success page or to Courier's website
  const onFormSuccess = async (data: SubmitCourierResponse, variables: SubmitCourierData) => {
    if (variables.courier === 'canada-post') {
      if (!data.canadaPostTokenId) throw new Error('Missing parameters');

      submitCanadaPost({
        id: data.canadaPostTokenId,
        returnUrl: `${import.meta.env.VITE_APP}/redirect`,
      });
    } else if (variables.courier === 'ups') {
      if (!data.id || !currentUser?.companyId) {
        upsRedirectError();
      } else {
        localStorage.setItem(data.id, JSON.stringify(variables.data));
        fetchUpsRedirectUrl(
          {
            companyId: currentUser.companyId,
            upsAccountId: data.id,
          },
          {
            onSuccess: (url) => {
              window.top ? (window.top.location.href = url) : (window.location.href = url);
            },
            onError: () => upsRedirectError(),
          },
        );
      }
    } else {
      postMessage('success', 'Courier account added successfully');
      navigate(routesList.success, {
        state: { courier: variables.courier },
      });
    }
  };

  // If courier connection is unsuccessful, redirect to error page
  const onFormError = (error: Error) => {
    const message = axios.isAxiosError(error)
      ? error.response?.data?.status
      : 'An error has occurred while adding the courier account';
    postMessage('error', message);
    navigate(routesList.error, {
      state: { message },
    });
  };

  // If UPS redirection is unsuccessful, redirect to error page
  const upsRedirectError = () => {
    const message = "We couldn't redirect you to UPS website. Please try again later.";
    postMessage('error', message);
    navigate(routesList.error, {
      state: { message },
    });
  };

  const submitForm = (data: object) => {
    const companyId = currentUser?.companyId;

    if (!companyId) throw new Error('Unable to submit');

    submitCourier(
      {
        companyId,
        courier: selectedCourier.courierId,
        data: { ...data, originCountryId: country?.id },
      },
      { onSuccess: onFormSuccess, onError: onFormError },
    );
  };

  const LogoComponent = useMemo<() => React.ReactNode>(() => {
    return () => (
      <img
        className="h-[64px] rounded"
        src={selectedCourier.logoUrl}
        alt={`${selectedCourier.umbrellaName} logo`}
      />
    );
  }, [selectedCourier]);

  const FormComponent = useMemo<() => React.ReactNode>(() => {
    const form = formComponentList.find((i) =>
      Object.values(i.COMPATIBLE_UMBRELLA_NAMES).includes(selectedCourier.umbrellaName),
    );

    if (!form) {
      return () => (
        <GenericForm.FormComponent
          umbrellaName={selectedCourier.umbrellaName}
          onSuccess={submitForm}
        />
      );
    }

    return () => (
      <form.FormComponent umbrellaName={selectedCourier.umbrellaName} onSuccess={submitForm} />
    );
  }, [selectedCourier, submitForm]);

  if (isLoading) return <Pulse show={true} />;

  return (
    <Layout leadingElement={<LogoComponent />} title={`Connect ${selectedCourier?.umbrellaName}`}>
      <p className="mb-4">
        Authenticate your <strong>{selectedCourier?.umbrellaName}</strong>{' '}
        {country?.name && !isSingleCountryCourier(selectedCourier?.logoUrl || '') && (
          <strong>{country?.name}</strong>
        )}{' '}
        account to start shipping with your own negotiated rates.
      </p>

      <FormComponent />
    </Layout>
  );
};
