import "./styles/index.css";
import "@/services/axiosRequestInterceptors";
import React from "react";
import ReactDOM from "react-dom/client";
import QueryClientProvider from "@/providers/QueryClientProvider";
import { UserProvider } from "./providers/UserProvider";
import { Toastify } from "@/components/Toastify";
import { DefaultRouter } from "./router/DefaultRouter";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import * as Sentry from "@sentry/react";
import { API } from "@/utils/apiConstants";

const isDevEnv = import.meta.env.VITE_ENV_NAME !== "production";

// TODO: revalidate config
// https://easyship.slack.com/archives/C671X3095/p1719241413873239
Sentry.init({
  enabled: !isDevEnv,
  environment: import.meta.env.MODE,
  dsn: "https://32c44be4c2775081cc2893d1c3a14111@o177970.ingest.us.sentry.io/4507253747875840",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", API.baseEndpoint],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider>
      <UserProvider>
        <ErrorBoundary>
          <Toastify />
          <DefaultRouter />
        </ErrorBoundary>
      </UserProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
