import { Courier } from '@/models/Courier';

// Show the same form for different AU Post related couriers
export const isAustraliaPostCourier = (logoUrl: string) => {
  const australiaPostRelatedCouriers = ['australia-post', 'australia-post-on-demand', 'star-track'];

  return australiaPostRelatedCouriers.includes(logoUrl);
};

export const isSingleCountryCourier = (logoUrl: string) => {
  const nonInterationalCouriers = [
    'australia-post',
    'australia-post-on-demand',
    'star-track',
    'sendle',
    'australia-post-mypost-business',
    'canada-post',
    'royal-mail',
    'passport',
  ];

  return nonInterationalCouriers.includes(logoUrl);
};
