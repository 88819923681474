import { Country } from '@/models/Address';
import { Courier } from '@/models/Courier';
import { createContext, useContext, useState } from 'react';

/*
Context used for data related to user actions:
  - country selection
*/

interface UserContextProps {
  country: Country | undefined;
  selectedCourier: Courier | undefined;
  setCountry: React.Dispatch<React.SetStateAction<Country | undefined>>;
  setSelectedCourier: React.Dispatch<React.SetStateAction<Courier | undefined>>;
}

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserContext = createContext<UserContextProps>({} as UserContextProps);

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }: UserProviderProps) => {
  const [country, setCountry] = useState<Country>();
  const [selectedCourier, setSelectedCourier] = useState<Courier>();

  return (
    <UserContext.Provider value={{ country, setCountry, selectedCourier, setSelectedCourier }}>
      {children}
    </UserContext.Provider>
  );
};
