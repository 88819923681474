import { API } from '@/utils/apiConstants';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface UseUpsRedirectUrlMutationParams {
  companyId: string;
  upsAccountId: string;
}

export const useUpsRedirectUrlMutation = () => {
  return useMutation({
    mutationFn: async ({
      companyId,
      upsAccountId,
    }: UseUpsRedirectUrlMutationParams): Promise<string> => {
      const { data } = await axios.get(
        `${API.baseEndpoint}/api/v1/companies/${companyId}/courier_accounts/${upsAccountId}/authorize`,
      );
      return data?.authorize_url;
    },
  });
};
