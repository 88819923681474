import axios from 'axios';
import Cookies from 'js-cookie';
import { API } from '@/utils/apiConstants';

axios.interceptors.request.use(
  (config) => {
    const credentialsEnv = API.appCredentials;
    const credentials = Cookies.get(credentialsEnv);
    if (credentials) {
      config.headers['Authorization'] = `Bearer ${credentials}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
