import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import * as CC from 'change-case';
import { API } from '@/utils/apiConstants';

export interface SubmitCourierData {
  companyId: string;
  courier: string;
  data: object;
}

export interface SubmitCourierResponse {
  canadaPostTokenId?: string;
  id?: string;
}

export const useSubmitCourierMutation = () => {
  return useMutation({
    mutationFn: async ({
      companyId,
      courier,
      data,
    }: SubmitCourierData): Promise<SubmitCourierResponse> => {
      const formattedData: { [k: string]: string } = {};
      Object.keys(data).map((key) => {
        const keyWithNumber = key.split(/(\d+)/).join('_');
        const formattedKey = CC.snakeCase(keyWithNumber);
        return (formattedData[formattedKey] = data[key as keyof typeof data]);
      });

      return await axios.post(
        `${API.baseEndpoint}/api/v1/companies/${companyId}/courier_accounts/${courier === 'ups' ? 'ups-ready-oauth' : courier}`,
        { account: formattedData },
      );
    },
  });
};
